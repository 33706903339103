import { CUSTOMER, DRIVER, LOADING, UNLOADING } from "../freights/FreightFieldsOptions";
export const BOTH_EVENTS = 'both_events';
export const BOTH_TARGETS = 'both_targets';
export const EVENT_OPTIONS = [{
  value: LOADING,
  label: 'Carregamento'
}, {
  value: UNLOADING,
  label: 'Descarregamento'
}, {
  value: BOTH_EVENTS,
  label: 'Ambos'
}];
export const TARGET_OPTIONS = [{
  value: DRIVER,
  label: 'Motorista'
}, {
  value: CUSTOMER,
  label: 'Embarcador'
}, {
  value: BOTH_TARGETS,
  label: 'Ambos'
}];