import { buildFeatures as accountingBuildFeatures, loadFeature as accountingLoadFeature, AccountingCompaniesPage, AccountingProductsPage } from 'accounting-front';
import { buildFeatures as acquisitionBuildFeatures, loadFeature as acquisitionLoadFeature } from 'acquisition-front';
import { buildFeatures as adminBuildFeatures, loadFeature as adminLoadFeature } from 'admin-front';
import { buildFeatures as cooperativeBuildFeatures, loadFeature as cooperativeLoadFeature } from 'cooperative-front';
import { buildFeatures as financialbuildFeatures, loadFeature as financialLoadFeature, AccountsPage } from "financial-front";
import { buildFiscalFeatures, loadFeature as fiscalLoadFeature, buildPurchaseFeatures, buildSellFeatures, FiscalPeoplePage, FiscalProductsAnalysisPage } from 'fiscal-front';
import { buildFeatures as logisticBuildFeatures, loadFeature as logisticLoadFeature } from 'freighter-front';
import { buildFeatures as fleetBuildFeatures, loadFeature as fleetLoadFeature } from 'fleet-front';
import { buildFeatures as stockBuildFeatures, loadFeature as stockLoadFeature } from 'stock-front';

export const ALL_FEATURES = [
  adminLoadFeature(adminBuildFeatures(), FiscalPeoplePage, AccountingCompaniesPage),
  financialLoadFeature(financialbuildFeatures()),
  stockLoadFeature(stockBuildFeatures(), AccountingProductsPage, FiscalProductsAnalysisPage),
  fiscalLoadFeature(buildPurchaseFeatures()),
  fiscalLoadFeature(buildSellFeatures()),
  fiscalLoadFeature(buildFiscalFeatures()),
  acquisitionLoadFeature(acquisitionBuildFeatures()),
  cooperativeLoadFeature(cooperativeBuildFeatures(), AccountsPage),
  logisticLoadFeature(logisticBuildFeatures()),
  fleetLoadFeature(fleetBuildFeatures()),
  accountingLoadFeature(accountingBuildFeatures()),
]