import { packObjectList } from 'grifo-components';
import { CONVENTIONAL, DEFAULT_PRODUCT_MEASURE_DESCRIPTION, RECEIVER_WITHDRAW_NO, RECEIVER_WITHDRAW_YES, ROAD, SERVICE_TAKER_SENDER } from 'fiscal-front';
import { DANGEROUS_CARGO, ESCORTED, ORDINARY_CARGO, SAFE_CARGO, SENSITIVE_CARGO, UNSENSITIVE_CARGO, VALUABLE_CARGO, WITHOUT_ESCORT } from '../constants/contracts/ContractFieldsOptions';
import { FOB, LOADING_FREE, NORMAL, SHIPPING_COMPANY, UNLOADING_FREE, WITHOUT_CANVAS, WITHOUT_PRECIFICATION, WITHOUT_TRACKER } from "../constants/freights/FreightFieldsOptions";
export const paramBuilder = (record, values, fromContract = false) => {
  return {
    id: record?.id || null,
    operationId: values.operationId,
    tracker: values.tracker,
    priority: values.priority,
    canvas: values.canvas,
    modal: values.modal,
    freightMethod: values.freightMethod,
    serviceType: values.serviceType,
    serviceTakerIdentifier: values.serviceTakerIdentifier,
    loadingTax: values.loadingTax,
    unloadingTax: values.unloadingTax,
    description: values.description,
    additionalTransportationFeature: values.additionalTransportationFeature,
    additionalServiceFeature: values.additionalServiceFeature,
    additionalCargoFeature: values.additionalCargoFeature,
    receiverWithdraw: values.receiverWithdraw ? RECEIVER_WITHDRAW_YES : RECEIVER_WITHDRAW_NO,
    receiverDetail: values.receiverDetail,
    truckTires: values.truckTires,
    truckAxis: values.truckAxis,
    truckHeight: values.truckHeight,
    truckWidth: values.truckWidth,
    truckLength: values.truckLength,
    personOriginId: values.personOriginId,
    addressOriginId: values.addressOriginId,
    personDestinationId: values.personDestinationId,
    addressDestinationId: values.addressDestinationId,
    serviceTakerId: values.serviceTakerId,
    serviceTakerAddressId: values.serviceTakerAddressId,
    dispatcherId: values.dispatcherId,
    receiverId: values.receiverId,
    pricingMethod: values.pricingMethod,
    pricingValue: values.pricingValue || 0,
    advanceMethod: values.advanceMethod,
    advanceUnitaryValue: values.advanceUnitaryValue || 0,
    bodyworks: packObjectList(values.bodyworks || [], "bodyworkId"),
    vehicleTypes: packObjectList(values.vehicleTypes || [], "typeId"),
    products: (values.products || []).map((object, index) => {
      delete object.product;
      delete object.commercialUnit;
      delete object.packingKind;
      delete object.quantityLoaded;
      delete object.quantityUnloaded;
      if (!fromContract) delete object.totalQuantityDelivered;
      return {
        ...object,
        prevalent: object.prevalent || false,
        escort: object.escort ? ESCORTED : WITHOUT_ESCORT,
        dangerousCargo: object.dangerousCargo ? DANGEROUS_CARGO : SAFE_CARGO,
        sensitiveCargo: object.sensitiveCargo ? SENSITIVE_CARGO : UNSENSITIVE_CARGO,
        valuableCargo: object.valuableCargo ? VALUABLE_CARGO : ORDINARY_CARGO
      };
    }),
    insurances: (values.insurances || []).map((insurance, index) => {
      delete insurance.insuranceCompany;
      delete insurance.naturalPerson;
      delete insurance.juridicalPerson;
      return {
        ...insurance,
        juridicalPersonId: insurance.liability === SHIPPING_COMPANY ? insurance.insuranceCompanyId : insurance.naturalPersonId
      };
    })
  };
};
export const defaultBuilder = (record, props = {}) => {
  const object = record || {};
  return {
    id: object.id,
    operationId: object.operationId,
    tracker: object.tracker || WITHOUT_TRACKER,
    priority: object.priority || NORMAL,
    loadingTax: object.loadingTax || LOADING_FREE,
    unloadingTax: object.unloadingTax || UNLOADING_FREE,
    canvas: object.canvas || WITHOUT_CANVAS,
    modal: object.modal || ROAD,
    serviceType: object.serviceType || CONVENTIONAL,
    serviceTakerIdentifier: object.serviceTakerIdentifier || SERVICE_TAKER_SENDER,
    freightMethod: object.freightMethod || FOB,
    description: object.description,
    additionalTransportationFeature: object.additionalTransportationFeature,
    additionalServiceFeature: object.additionalServiceFeature,
    additionalCargoFeature: object.additionalCargoFeature,
    receiverWithdraw: object.receiverWithdraw === RECEIVER_WITHDRAW_YES,
    receiverDetail: object.receiverDetail,
    pricingMethod: object.pricingMethod || WITHOUT_PRECIFICATION,
    pricingValue: object.pricingValue,
    advanceMethod: object.advanceMethod || WITHOUT_PRECIFICATION,
    advanceUnitaryValue: object.advanceUnitaryValue || 0,
    truckTires: object.truckTires,
    truckAxis: object.truckAxis,
    truckHeight: object.truckHeight,
    truckWidth: object.truckWidth,
    truckLength: object.truckLength,
    personOriginId: object.personOrigin?.id,
    addressOriginId: object.addressOrigin?.id,
    personDestinationId: object.personDestination?.id,
    addressDestinationId: object.addressDestination?.id,
    serviceTakerId: object.serviceTakerId,
    serviceTakerAddressId: object.serviceTakerAddressId,
    dispatcherId: object.dispatcherId,
    receiverId: object.receiverId,
    products: (object.products || [{
      quantity: 1,
      prevalent: true,
      measureType: DEFAULT_PRODUCT_MEASURE_DESCRIPTION
    }]).map(product => {
      return {
        ...product,
        unitForTransport: product.commercialUnit?.unitForTransport,
        commercialUnitId: product.commercialUnit?.id,
        packingKindId: (product.packingKind || {}).id,
        escort: product.escort === ESCORTED,
        dangerousCargo: product.dangerousCargo === DANGEROUS_CARGO,
        sensitiveCargo: product.sensitiveCargo === SENSITIVE_CARGO,
        valuableCargo: product.valuableCargo === VALUABLE_CARGO,
        width: product.width || 0,
        length: product.length || 0,
        height: product.height || 0,
        totalQuantityDelivered: object.totalQuantityDelivered || 0
      };
    }),
    insurances: (object.insurances || [{
      value: 0,
      liability: SHIPPING_COMPANY
    }]).map(insurance => {
      return {
        ...insurance
      };
    }),
    bodyworks: object.bodyworks,
    vehicleTypes: object.vehicleTypes,
    advanceMethod: object.advanceMethod || WITHOUT_PRECIFICATION,
    advanceUnitaryValue: object.advanceUnitaryValue || 0
  };
};