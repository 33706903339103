import gql from "graphql-tag";
import { addressFragment, phoneFragment, personSimpleFragment } from 'admin-front';
import { operationFragment } from "./Operation";
import { movementFragment } from "./Movement";
import { AccountPaymentFragment } from "./AccountPayment";
import { movementEntryFragment } from "./Movement";
export const accountSimpleFragment = gql`
  fragment AccountSimple on Account {
    id,
    transactionId,
    origin,
    kind,
    value,
    balance,
    earlyPaymentDeduction,
    earlyPaymentDeductionValue,
    latePaymentInterest,
    latePaymentInterestValue,
    latePaymentInterestKind,
    latePaymentFineValue,
    latePaymentFine,
    date,
    dueDate,
    setup,
    dateStatus,
    dueDateStatus,
    daysEllapsed,
    daysToDue,
    installmentNumber,
    totalInstallments,
    rescheduledAccount,
    reschedulingAccount,
    reschedulingAccountId,
    status,
    hasChildren,
    canEdit,
    companyId,
    operationId,
    personId,
    person {
      ...PersonSimple
    },
    payments {
      ...AccountPayment
    }
  }
  ${personSimpleFragment}
  ${AccountPaymentFragment}
`;
export const accountFragment = gql`
  fragment Account on Account {
    ...AccountSimple,
    operation {
      ...Operation
    },
    entry {
      ...MovementEntrySimple     
    },
    movements {
      ...Movement
    },
    writeOffMovements {
      ...Movement
    },
    parent {
      id,
    },
  }
  ${accountSimpleFragment}
  ${operationFragment}  
  ${movementFragment}
  ${movementEntryFragment}  
`;
export const parentAccountFragment = gql`
  fragment ParentAccount on Account {
    ...Account,
    installments {
      ...Account,
    } 
  }
  ${accountFragment}
`;
export const parentAccountSimpleFragment = gql`
  fragment ParentAccountSimple on Account {
    ...AccountSimple,
    installments {
      ...AccountSimple,
    } 
  }
  ${accountSimpleFragment}
`;